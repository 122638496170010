<template>
  <div>
    <!-- 检索 -->
    <el-form :inline="true" :model="form" ref="form">
      <el-form-item class="form-item" label="标签名称" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入标签名称"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="标签分类" prop="type">
        <el-select v-model="form.type" placeholder="全部" clearable>
          <el-option label="分类一" value="1"></el-option>
          <el-option label="分类二" value="2"></el-option>
          <el-option label="分类三" value="3"></el-option>
          <el-option label="分类四" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="是否启用" prop="mark">
        <el-select v-model="form.mark" placeholder="全部" clearable>
          <el-option label="启用" value="1"></el-option>
          <el-option label="禁用" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->

    <div class="table-content">
      <div class="table-title">
        <div>标签列表</div>
        <el-button
          v-if="type.indexOf('添加') > -1"
          type="primary"
          @click="handTagAdd"
          >添 加</el-button
        >
      </div>

      <!-- 列表 -->
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          fixed="left"
          prop="title"
          label="标签名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="user_count"
          label="用户数量"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          label="标签分类"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建标签时间"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updated_at"
          label="最近编辑时间"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column label="状态" width="150" align="center">
          <template #default="scope">
            <div @click="handType(scope.row.mark, scope.$index)">
              <el-switch
                v-model="scope.row.mark"
                :active-value="1"
                :inactive-value="2"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          min-width="200"
          show-overflow-tooltip
          align="center"
        >
          <template #default="scope">
            <el-button
              v-if="type.indexOf('编辑') > -1"
              @click="handleClick(scope.row.id)"
              type="text"
              size="small"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 列表 -->

      <!-- 分页 -->
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
      <!-- 分页 -->
    </div>
    <el-dialog :title="title" v-model="dialogFormVisible" width="500px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        ref="ruleForm"
      >
        <el-form-item label="标签分类：" prop="type">
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择标签分类"
            style="width: 300px"
          >
            <el-option label="分类一" :value="1"></el-option>
            <el-option label="分类二" :value="2"></el-option>
            <el-option label="分类三" :value="3"></el-option>
            <el-option label="分类四" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签名称：" prop="title">
          <el-input
            v-model="ruleForm.title"
            placeholder="请输入标签名称"
            :maxlength="20"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="onResetForm('ruleForm')">重 置</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  tagList,
  tagEdit,
  tagDetail,
  tagStart,
  tagStop,
} from "@/api/apiList/user-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        title: "",
        type: "",
        mark: "",
      },
      ruleForm: {
        id: "",
        type: "",
        title: "",
      },
      rules: {
        type: [
          { required: true, message: "请选择标签分类", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入标签名称", trigger: "blur" }],
      },
      tableData: [],
      title: "创建标签",
      dialogFormVisible: false,
      loading: true,
      pageSize: 10,
      currentPage: 1,
      total: 10,
      type: "",
    };
  },
  methods: {
    // 查询
    onSubmit() {
      this.loading = true;
      this.handList();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 添加
    handTagAdd() {
      this.title = "创建标签";
      if (this.ruleForm.id) {
        this.onResetForm("ruleForm");
      }
      this.ruleForm = {
        id: "",
        type: "",
        title: "",
      };
      this.dialogFormVisible = true;
    },
    // 启用/禁用
    handType(e, index) {
      let obj = {
        id: this.tableData[index].id,
      };
      if (e === 1) {
        this.$confirm("是否启用该标签?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            tagStart(obj).then((res) => {
              if (res.result === "200") {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            });
          })
          .catch(() => {
            this.tableData[index].mark = 2;
          });
      } else {
        this.$confirm("是否禁用该标签?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            tagStop(obj).then((res) => {
              if (res.result === "200") {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            });
          })
          .catch(() => {
            this.tableData[index].mark = 1;
          });
      }
    },
    // 编辑
    handleClick(e) {
      let obj = {
        id: e,
      };
      tagDetail(obj).then((res) => {
        if (res.result === "200") {
          this.ruleForm.id = e;
          this.ruleForm.type = res.data.type;
          this.ruleForm.title = res.data.title;
          this.title = "编辑标签";
          this.dialogFormVisible = true;
        }
      });
    },
    // 添加/编辑提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          tagEdit(this.ruleForm).then((res) => {
            if (res.result === 200) {
              this.dialogFormVisible = false;
              this.$message.success({
                message: res.msg,
                type: "success",
              });
              this.handList();
            }
          });
        } else {
          return false;
        }
      });
    },
    onResetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    handList() {
      tagList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.type) {
              case 1:
                item.type = "分类一";
                break;
              case 2:
                item.type = "分类二";
                break;
              case 3:
                item.type = "分类三";
                break;
              case 4:
                item.type = "分类四";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    this.handList();
  },
};
</script>

<style></style>
